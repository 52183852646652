import { useState, useEffect } from 'react';

import Mqtt from 'utils/MqttEngine';
import { MqttTopics } from 'utils/Constants';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
    const [doorStateLeft, setDoorStateLeft] = useState(0);
    const [doorStateRight, setDoorStateRight] = useState(0);
    const [doorLockLeft, setDoorLockLeft] = useState(0);
    const [doorLockRight, setDoorLockRight] = useState(0);

    const [leftOpen, setLeftOpen] = useState(false);
    const [rightOpen, setRightOpen] = useState(false);
    const [bothOpen, setBothOpen] = useState(false);

    const TOPICS = [
        MqttTopics._DOOR_STATE_LEFT,
        MqttTopics._DOOR_STATE_RIGHT,
        MqttTopics._DOOR_LOCK_LEFT,
        MqttTopics._DOOR_LOCK_RIGHT,
    ];

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    const handleMessage = (topic, message) => {
        switch(topic) {
            case MqttTopics._DOOR_STATE_LEFT: {
                setDoorStateLeft(parseInt(message));
                break;
            }
            case MqttTopics._DOOR_STATE_RIGHT: {
                setDoorStateRight(parseInt(message));
                break;
            }
            case MqttTopics._DOOR_LOCK_LEFT: {
                setDoorLockLeft(parseInt(message));
                break;
            }
            case MqttTopics._DOOR_LOCK_RIGHT: {
                setDoorLockRight(parseInt(message));
                break;
            }
            default: {
                // topic does not exist
            }
        }
    }

    const publishLeft = () => {
        if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            navigator.vibrate(200);
        }

        Mqtt.getInstance().publish(MqttTopics._DOOR_LOCK_LEFT, !leftOpen ? 1 : 0);
    }

    const publishRight = () => {
        if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            navigator.vibrate(200);
        }

        Mqtt.getInstance().publish(MqttTopics._DOOR_LOCK_RIGHT, !rightOpen ? 1 : 0);
    }

    const publishBoth = () => {
        if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            navigator.vibrate(200);
        }

        Mqtt.getInstance().publish(MqttTopics._DOOR_LOCK_LEFT, !leftOpen ? 1 : 0);
        Mqtt.getInstance().publish(MqttTopics._DOOR_LOCK_RIGHT, !rightOpen ? 1 : 0);
    }

    useEffect(() => {
        if (leftOpen && rightOpen) {
            setBothOpen(true);
        }
        else {
            setBothOpen(false);
        }
    }, [leftOpen, rightOpen]);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        setLeftOpen(doorLockLeft === 1 ? true : false)
    }, [doorLockLeft]);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        setRightOpen(doorLockRight === 1 ? true : false)
    }, [doorLockRight]);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        setBothOpen(doorLockLeft === 1 && doorLockRight === 1)
    }, [doorLockLeft, doorLockLeft]);

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    useEffect(() => {
        Mqtt.getInstance().subscribe(TOPICS);
        Mqtt.getInstance().onMessageWithTopic(handleMessage);

        return () => {
            Mqtt.getInstance().unsubscribe(TOPICS);
        }
    }, []);

    return (
        <div className="dashboard-container">
            <div className="row">
                <div className={`card mr-5 ${doorLockLeft ? 'open' : 'close'}`}>
                    <div className="card-title">Left Lock</div>
                </div>
                <div className={`card ml-5 ${doorLockRight ? 'open' : 'close'}`}>
                    <div className="card-title">Right Lock</div>
                </div>
            </div>
            <div className="row">
                <div className="backDoor">  
                    <div className={`door left ${doorStateLeft ? 'open' : '' }`}></div>
                </div> 
                <div className="backDoor">  
                    <div className={`door right ${doorStateRight ? 'open' : '' }`}></div>
                </div>
            </div>
            <div className="buttons"> 
                <div className="row">   
                    <button className="pushable" onClick={publishLeft}>
                        <span className="shadow"></span>
                        <span className={`edge ${leftOpen ? 'closed' : '' }`}></span>
                        <span className={`front ${leftOpen ? 'closed' : '' }`}>Left Door</span>
                    </button>
                    <button className="pushable" onClick={publishRight}>
                        <span className="shadow"></span>
                        <span className={`edge ${rightOpen ? 'closed' : '' }`}></span>
                        <span className={`front ${rightOpen ? 'closed' : '' }`}>Right Door</span>
                    </button>
                </div>
                <button className="pushable" onClick={publishBoth}>
                    <span className="shadow"></span>
                    <span className={`edge ${bothOpen ? 'closed' : '' }`}></span>
                    <span className={`front ${bothOpen ? 'closed' : '' }`}>Both Door</span>
                </button>
            </div>
        </div>
    );
}