import { useForm } from "react-hook-form";
import { useHistory } from 'react-router';

import LogoImg from 'assets/images/logo.png';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
    let history = useHistory();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            username: ""
        }
    });

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    const onSubmit = (data) => {
        history.push('/dashboard');
    }

    return (
        <div className="login-container">
            <img className="logo" src={LogoImg} />
            <div className="header">IOT System</div>
        
            <div className="input-container">
                <input {...register('username', {
                    required: true
                })} type="text" placeholder=" " />
                <span className="label">Username</span>
                <span className="label-sm">Username</span>
            </div>

            <div className="input-container">
                <input {...register('password', {
                    required: true
                })} type="password" placeholder=" " />
                <span className="label">Password</span>
                <span className="label-sm">Password</span>
            </div>

            <div className="buttons">
                <button type="button" className="btn-signup">Sign Up</button>
                <button type="button" className="btn-login" onClick={onSubmit}>Login</button>
            </div>
        </div>
    );
}