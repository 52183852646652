export const _MQTT_PROTOCOL = "ws";
export const _MQTT_PORT = "9001";
export const _MQTT_ENDPOINT = "mqttiot.gzit.com.my";

const _MQTT_BASE_TOPIC = "raspi-fridge/";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const MqttTopics = {
    _IOT_POC: _MQTT_BASE_TOPIC + "mqtt",
    _DOOR_LOCK: _MQTT_BASE_TOPIC + "door-lock",
    _DOOR_STATE: _MQTT_BASE_TOPIC + "door-state",
    _DOOR_STATE_LEFT: _MQTT_BASE_TOPIC + "door-state-l",
    _DOOR_STATE_RIGHT: _MQTT_BASE_TOPIC + "door-state-r",
    _DOOR_LOCK_LEFT: _MQTT_BASE_TOPIC + "door-lock-l",
    _DOOR_LOCK_RIGHT: _MQTT_BASE_TOPIC + "door-lock-r",
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const DoorState = {
    _CLOSE: 0,
    _OPEN: 1
}