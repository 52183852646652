import mqtt from 'mqtt';
import { stringIsNullOrEmpty } from './Util';
import { _MQTT_PROTOCOL, _MQTT_PORT, _MQTT_ENDPOINT } from './Constants';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default class MqttEngine {
    static _client = null;

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    constructor() {
        var options = {
            username: 'sub_potens',
            password: 'sub_potens@pw',
            clientId: 'GZ-IOT-' + Math.floor((Math.random() * 10000) + 1)
        };

        let mqttUrl = `${_MQTT_PROTOCOL}://${_MQTT_ENDPOINT}:${_MQTT_PORT}`;

        const client = mqtt.connect(mqttUrl, options);
        client.stream.on('error', (err) => {
            console.log('Error: Failed to connect to mqtt broker.');
            client.end();
        });

        this._client = client;
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    static getInstance() {
        if (stringIsNullOrEmpty(MqttEngine._client)) {
            MqttEngine._client = new MqttEngine();
        }

        return MqttEngine._client;
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    publish(topic, message) {
        this._client.publish(topic, JSON.stringify(message));
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    subscribe(topic) {
        const callBack = (error, granted) => {
            if (error) {
                console.log('Subscription request failed. Topic: ' + topic);
                console.log('Error: ' + error);
            }
        }
    
        this._client.subscribe(topic, callBack);
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    onMessage(callBack) {
        this._client.on('message', (topic, message, packet) => {
            callBack(message.toString());
        });
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    onMessageWithTopic(callBack) {
        this._client.on('message', (topic, message, packet) => {
            callBack(topic, message.toString());
        });
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    unsubscribe(topics) {
        if (typeof topics === "string") {
            this.destroyTopic(topics);
        }
        else {
            topics.map((topic) => {
                this.destroyTopic(topic);
            });
        }
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    destroyTopic(topic) {
        this._client.unsubscribe(topic);

        for (const key in this._client.messageIdToTopic) {
            for (let i = 0; i < this._client.messageIdToTopic[key].length; i++) {
                let elem = this._client.messageIdToTopic[key][i];

                if (elem == topic) {
                    this._client.messageIdToTopic[key].splice(i, 1);
                }
            }

            if (this._client.messageIdToTopic[key].length<=0) {
                delete this._client.messageIdToTopic[key];
            }
        }
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    closeConnection() {
        this._client.end();
    }

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    refresh() {
        this._client = null;
        this._client = new MqttEngine();
    }
}
