import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import './App.scss';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
    return (
        <Router>
            <Switch>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    )
}
